$primary-color: #EE1748;
$secondary-color: #F2AB3D;

.btn-primary {
  color: white;
  border-color: $primary-color;
  background-color: $primary-color;
}

.btn-primary:hover {
  color: #fff;
  background-color: $secondary-color;
  border-color: $secondary-color;
}

.btn-primary:not(:disabled):not(.disabled):active,
.wizard>.actions a:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.wizard>.actions a:not(:disabled):not(.disabled).active,
.show>.btn-primary.dropdown-toggle,
.wizard>.actions .show>a.dropdown-toggle {
  color: #fff;
  background-color: $primary-color;
  border-color: $primary-color;
}

.sidebar .nav .nav-item.active>.nav-link {
  position: relative;
  background-color: $primary-color;
}

.sidebar .nav:not(.sub-menu)>.nav-item:hover>.nav-link {
  background-color: $primary-color;
  color: white;
}

.sidebar .nav:not(.sub-menu)>.nav-item:hover>.nav-link i {
  background-color: $primary-color;
  color: white;
}

.btn-primary.nav-pills {
  border-bottom: 0px solid $primary-color;
  padding-bottom: 1rem;
  font-weight: 500;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: #fff;
  background-color: $primary-color;
}


.font-primary {
  color: $primary-color;
}

.font-primary_2 {
  color: $primary-color;
}

.font-primary_3 {
  color: $primary-color;
}

.font-primary_4 {
  color: $primary-color;
}